@import 'assets/styles/mixins/variables.scss';

.iconAccept {
  &:hover {
    color: $green1;
  }
  composes: mr-3 cursor-pointer from global;
}

.iconDecline {
  &:hover {
    color: $red1;
  }
  composes: cursor-pointer from global;
}
