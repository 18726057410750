@import "./../../assets/styles/mixins/variables";

.pagination {
  margin-bottom: 0;
  justify-content: flex-end;

  .page-item {

    .page-link {
      border: none;
      background-color: transparent;
      color: black;
      outline: none!important;
      box-shadow: none!important;

      &:hover {
        color: $orange1;
      }
    }

    &.disabled .page-link {
      background-color: transparent;
      border: none;
    }

    &.active .page-link {
      color: $orange1;
      background-color: transparent;
      border: none;
    }

  }

}
