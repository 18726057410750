@import "../mixins/variables";

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .login-logo-row {
    height: 32px;
  }

  .login-form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 25px;

    &-brain {
      position: absolute;
      left: 0;
      top: 0;
      width: 30%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: right;
      background-size: contain;
      transform: translateX(-100%);
      border-right: 2px solid $orange2;
    }

    .form-group {
      width: 100%;

      .form-label {
       font-size: 0.625rem;
        color: #878888;
      }

      input {
        font-size: 1rem;
        border: 1px solid #D5D5D5;
        border-radius: 8px;
      }
    }
  }

  .login-image {
    width: 100%;
    padding: 29%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

}
