@import "./mixins/variables";

$font-stack: 'Montserrat', 'Muli', -apple-system, BlinkMacSystemFont, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;

$spacer: 1rem;

*,
::after,
::before {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  color: #137eff;
  @include transition(all 0.3s ease);
}

html,
body,
#root {
  height: 100%;
  font-family: $font-stack;
}

.main-layout {
  height: 100%;
  background: #f2f1f4;
}

.big-container {
  margin-left: 200px;
  max-height: 100%;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    margin-left: 65px;
  }
}

.content {
  padding: 80px 20px 20px;
  @media screen and (max-width: 768px) {
    padding: 80px 10px 20px;
  }
}

.main-title {
  margin-bottom: 30px;
  font-size: 1.8rem;
}

.date-range-group {
  .btn {
    background-color: #898894;
    font-size: 14px;
    border-radius: 8px !important;
    border: none;
    font-family: 'Montserrat', sans-serif, normal;
    margin: 0 19px !important;
    position: relative;

    &:hover {
      color: #898894;
      background-color: #fff;
    }

    &:after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      display: inline-block;
      border-radius: 50%;
      right: -20px;
      top: 45%;
      background-color: #898894;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

button {
  $btnSize: 16px;

  position: relative;
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  text-align: center;
  font-style: normal;
  border-radius: 8px;
  font-size: 0.75rem;
  line-height: 0.8rem;
  padding: 0.35rem 0.85rem;
  margin: 0;
  user-select: none;
  border: 1px solid transparent;
  color: white;
  background-color: transparent;
  transition: background-color 0.3s;
  outline: none;

  &.orange {
    background-color: $orange2;

    &:hover {
      background-color: white;
      color: $orange2;
      border: 1px solid $orange2;
    }
  }

  &.with-loader {
    &::after {
      position: absolute;
      visibility: hidden;
      z-index: -1000000;
      opacity: 0;
      content: "";
      margin-left: 6px;
      border-radius: 100%;
      width: $btnSize;
      height: $btnSize;
      min-width: $btnSize;
      min-height: $btnSize;
      background-image: url(./../../assets/svg/button-loader.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: default;
      box-shadow: none !important;
      transition: all 0s !important;
      pointer-events: none !important;
      opacity: 0.95;
      padding: 0.5rem .85rem;

      &::after {
        position: relative;
        visibility: visible;
        z-index: 1;
        opacity: 1;
        animation: spin 0.8s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }


  }
}

input {
  outline: none!important;
  box-shadow: none!important;
}


