.formInput {
  &[class~="form-control"] {
    height: auto;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    border: 1px solid #d5d5d5;
    font-size: 14px;
    &::placeholder {
      font-style: italic;
      color: #c4c4c4;
    }
    &:focus {
      border-color: darken(#d5d5d5, 15%);
    }
  }
}

.formLabel {
  color: #c4c4c4;
  font-size: 12px;
  composes: mb-0 from global;
}

.formError {
  &[class~="invalid-feedback"] {
    font-size: 12px;
  }
}