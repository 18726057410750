.label {
  color: #c4c4c4;
  font-size: 12px;
  composes: mb-0 from global;
}

.buttonCheckIcon {
  transform: scale(2);
  composes: mr-2 from global;
}

.buttonCancelIcon {
  transform: scale(1.2) translateY(-10%);
  composes: mr-2 from global;
}

.creationContainer {
  text-align: right;
}