.form-select {
  &--label {
    color: #c4c4c4;
    font-size: 12px;
  }
  &--error {
    &[class~="invalid-feedback"] {
      font-size: 12px;
    }
  }

  &.is-invalid {
    .form-select__control {
      border-color: #dc3545 !important;
    }
  }
  
  &__control {
    height: auto;
    border-radius: 8px !important;
    padding: 0.5rem 1rem;
    border: 1px solid #d5d5d5 !important;
    font-size: 14px;
    &--is-focused {
      border-color: darken(#d5d5d5, 15%) !important;
      box-shadow: none !important;
    }
    [class$="-ValueContainer"] {
      padding: 0;
      .form-select__placeholder {
        font-style: italic;
        color: #c4c4c4;
      }
    }
  }
  &__menu-list {
    max-height: 130px !important;
  }
  &__indicator-separator {
    display: none !important;
  }
  &__indicator {
    padding: 0 !important;
  }
}
