//$cstinput-height: 40px;
//$cstinput-radius: 4px;
//$cstinput-padding: 8px 8px 8px 40px;
//$cstinput-font-size: 14px;
//$cstinput-bg: transparent;
//$cstinput-color: #4F4137;
//$cstinput-border: 1px solid #e9ebec;
//$cstinput-ph-color: $cstinput-color;
//$cstinput-phide-color: $cstinput-bg;
//
//$cstbutton-padding: 8px 18px 8px 18px;
//$cstbutton-color: #fff;
//$cstbutton-bg: #379aec;

$gray1: #707070;
$orange1: #EE8B38;
$orange2: #FFA54E;

$red1: #f44336;
$blue1: #2196f3;
$green1: #4caf50;

$carrot: #FE4F0B;
$orange: #FD9715;
$white: #ffffff;
$dashboardAside: #78787c;
$lightGrey: #F4F4F4;
$inputBorder: #D5D5D5;

$fontMontserrat: 'Montserrat', sans-serif;