@import "./../../assets/styles/mixins/variables";

.chart-wrapper {
  width: 100%;

  h4 {
    text-align: center;
    color: $gray1;
  }

  .chart-canvas-wrapper {
    width: 100%;
    height: 360px;
    canvas {
      height: 100%;
      width: 100%;
    }
  }

  .chart-label {
    color: $gray1;
    font-size: 1rem;
    font-weight: 500;
  }
}

.no-data-information {
  display: block;
  padding: 40px 10px;
  font-size: 20px;
  font-weight: 500;
  color: #a2a2a2;
  text-align: center;
  &.hide {
    display: none;
  }
}