@import 'assets/styles/mixins/variables.scss';

.iconDrop {
  color: #7E7E7E;
}

.iconAccept {
  &:hover {
    color: $green1;
  }
  composes: mr-3 cursor-pointer from global;
}

.iconDecline {
  &:hover {
    color: $red1;
  }
  composes: cursor-pointer from global;
}

.tagBadge span {
  font-size: 11px;
  padding: 2px 6px;
  height: 22px;
  line-height: 22px;
  margin-right: 5px;
  border-radius: 5px;
}
