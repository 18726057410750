@import "./../../assets/styles/mixins/variables";

.loader-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #80808010;
  z-index: 100;
  cursor: progress;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 101;

  text-indent: -9999em;
  border-top: .3em solid rgba(255, 255, 255, 0.6);
  border-right: .3em solid rgba(255, 255, 255, 0.6);
  border-bottom: .3em solid rgba(255, 255, 255, 0.6);
  border-left: .3em solid $orange1;
  -webkit-transform: translateZ(0) translate(-50%, -50%);
  -ms-transform: translateZ(0) translate(-50%, -50%);
  transform: translateZ(0) translate(-50%, -50%);
  -webkit-animation: load8 .7s infinite linear;
  animation: load8 .7s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
