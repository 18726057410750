.sidebar {
  width: 200px;
  padding: 16px;
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 9;

  .brand-area {
    margin-bottom: 30px;

    .logo {
      display: block;
      height: 50px;

      img {
        max-height: 100%;
        &[src$="logo-small.svg"] {
          display: none;
        }
      }
    }
  }

  .nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 !important;
  
    &-item {
      display: block;
      width: 100%;
    }
  
    &-link {
      position: relative;
      display: block;
      padding: 10px 12px !important;
      font-family: 'Montserrat', sans-serif, normal;
      font-size: 14px;
      font-weight: 400;
      color: #6a6873;
      border-radius: 8px;
  
      &:hover {
        color: #ee8136;
      }
  
  
      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 8px;
        background: transparent linear-gradient(76deg, #ee8136 0%, #f2b043 100%) 0 0 no-repeat padding-box;
        transform-origin: 0 100%;
        transform: scale(0, 0);
        transition: transform .3s ease-in-out;
      }
  
      &.active {
        color: #fff;
  
        &::before {
          //transform-origin: 100% 0;
          transform: scale(1, 1);
        }
      }
  
      &-name {
        &.new-update {
          &::after {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            border-radius: 100%;
            background-color: red;
          }
        }
      }
  
      svg {
        width: 20px;
        margin-right: 15px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 65px;
    padding: 10px;
    .brand-area {
      .logo {
        display: flex;
        justify-content: center;
        img {
          &[src$="logo.svg"] {
            display: none;
          }
          &[src$="logo-small.svg"] {
            display: inline-block;
          }
        }
      }
    }
    .nav {
      &-link {
        &-name {
          display: none;
        }
      }
    }
  }
}
