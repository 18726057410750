@import 'assets/styles/mixins/variables';

.label {
  color: #a3a3a3;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  composes: d-inline-flex align-items-center from global;
  &:hover {
    color: darken(#a3a3a3, 15%);
    .checkmark {
      border-color: darken(#a3a3a3, 15%);
      &:after {
        background-color: darken(#a3a3a3, 15%);
      }
    }
  }
}

.radio {
  visibility: hidden;
  width: 0;
  opacity: 0;
  &:checked {
    & ~ .checkmark {
      border-color: $orange;
      &:after {
        background-color: $orange;
      }
    }
  }
}

.checkmark {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #a3a3a3;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}