@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon/icomoon.eot?4k1rtv');
    src:  url('../fonts/icomoon/icomoon.eot?4k1rtv#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?4k1rtv') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?4k1rtv') format('woff'),
    url('../fonts/icomoon/icomoon.svg?4k1rtv#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-tick-right:before {
    content: "\e934";
}
.icon-tick-down:before {
    content: "\e937";
}
.icon-tick-up:before {
    content: "\e95b";
}
.icon-tick-left:before {
    content: "\e95c";
}
.icon-dots-horizontal:before {
    content: "\e95d";
}
.icon-invite:before {
    content: "\e95e";
}
.icon-arrow-slim-right:before {
    content: "\e95f";
}
.icon-arrow-slim-left:before {
    content: "\e960";
}
.icon-arrow-slim-top:before {
    content: "\e961";
}
.icon-arrow-slim-down:before {
    content: "\e962";
}
.icon-bell:before {
    content: "\e92d";
}
.icon-msg-bubble:before {
    content: "\e92e";
}
.icon-campaign:before {
    content: "\e930";
}
.icon-contacts-book:before {
    content: "\e931";
}
.icon-analytics:before {
    content: "\e932";
}
.icon-dual-displays:before {
    content: "\e933";
}
.icon-tag-alt:before {
    content: "\e935";
}
.icon-question-square:before {
    content: "\e936";
}
.icon-pencil:before {
    content: "\e938";
}
.icon-checkmark:before {
    content: "\e939";
}
.icon-undo:before {
    content: "\e93a";
}
.icon-redo:before {
    content: "\e93b";
}
.icon-play:before {
    content: "\e93c";
}
.icon-crown:before {
    content: "\e93d";
}
.icon-msg-bubble-dots:before {
    content: "\e93e";
}
.icon-sheet:before {
    content: "\e93f";
}
.icon-settings:before {
    content: "\e940";
}
.icon-Group-1347:before {
    content: "\e941";
}
.icon-calendar-alt:before {
    content: "\e942";
}
.icon-smile-emoji:before {
    content: "\e943";
}
.icon-eye:before {
    content: "\e944";
}
.icon-tranch-bin:before {
    content: "\e945";
}
.icon-x-circle:before {
    content: "\e946";
}
.icon-add-circle:before {
    content: "\e947";
}
.icon-download:before {
    content: "\e948";
}
.icon-messages:before {
    content: "\e949";
}
.icon-sms:before {
    content: "\e94a";
}
.icon-whats-app:before {
    content: "\e94b";
}
.icon-envelope:before {
    content: "\e94c";
}
.icon-scope-alt:before {
    content: "\e94d";
}
.icon-three-dots:before {
    content: "\e94e";
}
.icon-reload:before {
    content: "\e94f";
}
.icon-filter-alt:before {
    content: "\e950";
}
.icon-attach-alt-new:before {
    content: "\e951";
}
.icon-window-objects:before {
    content: "\e952";
}
.icon-msg-arrow:before {
    content: "\e953";
}
.icon-phone-ring:before {
    content: "\e954";
}
.icon-reload-alt:before {
    content: "\e955";
}
.icon-question-alt:before {
    content: "\e956";
}
.icon-checkmark-alt:before {
    content: "\e957";
}
.icon-bars:before {
    content: "\e958";
}
.icon-not-allowed:before {
    content: "\e959";
}
.icon-import:before {
    content: "\e95a";
}
.icon-copy:before {
    content: "\e92c";
}
.icon-invite-user:before {
    content: "\e92a";
}
.icon-pen:before {
    content: "\e92b";
}
.icon-clock:before {
    content: "\e929";
}
.icon-variables:before {
    content: "\e927";
}
.icon-tick:before {
    content: "\e928";
}
.icon-reset:before {
    content: "\e925";
}
.icon-tag:before {
    content: "\e926";
}
.icon-broadcast:before {
    content: "\e924";
}
.icon-close:before {
    content: "\e923";
}
.icon-message:before {
    content: "\e900";
}
.icon-close-circle:before {
    content: "\e901";
}
.icon-arrow-up-down:before {
    content: "\e902";
}
.icon-arrow-left:before {
    content: "\e903";
}
.icon-hamburger:before {
    content: "\e904";
}
.icon-inbox:before {
    content: "\e905";
}
.icon-share:before {
    content: "\e906";
}
.icon-contacts:before {
    content: "\e907";
}
.icon-bar-chart:before {
    content: "\e908";
}
.icon-arrows-left-right:before {
    content: "\e909";
}
.icon-gear:before {
    content: "\e90a";
}
.icon-wallet:before {
    content: "\e90b";
}
.icon-question:before {
    content: "\e90c";
}
.icon-scope:before {
    content: "\e90d";
}
.icon-refresh:before {
    content: "\e90e";
}
.icon-filter:before {
    content: "\e90f";
}
.icon-arrow-down:before {
    content: "\e910";
}
.icon-call-in:before {
    content: "\e911";
}
.icon-call-out:before {
    content: "\e912";
}
.icon-bookmark:before {
    content: "\e913";
}
.icon-call:before {
    content: "\e914";
}
.icon-list:before {
    content: "\e915";
}
.icon-user:before {
    content: "\e916";
}
.icon-volume:before {
    content: "\e917";
}
.icon-play-alt:before {
    content: "\e918";
}
.icon-dots:before {
    content: "\e919";
}
.icon-attach:before {
    content: "\e91a";
}
.icon-list-alt:before {
    content: "\e91b";
}
.icon-calendar:before {
    content: "\e91c";
}
.icon-dollar:before {
    content: "\e91d";
}
.icon-mic:before {
    content: "\e91e";
}
.icon-smile:before {
    content: "\e91f";
}
.icon-send:before {
    content: "\e920";
}
.icon-cloud:before {
    content: "\e921";
}
.icon-attach-alt:before {
    content: "\e922";
}
