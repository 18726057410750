@import "../mixins/variables";

.table-data-nav {
  display: flex;
  justify-content: center;
  border: none;
  @media screen and (max-width: 768px) {
    flex-wrap: nowrap;
  }

  .nav-item {
    position: relative;
    background-color: #898894;
    font-size: 14px;
    border-radius: 8px !important;
    border: none;
    font-family: 'Montserrat', sans-serif, normal;
    margin: 0 19px;
    color: white!important;
    @media screen and (max-width: 768px) {
      margin: 0 10px;
      padding: 0.2rem 0.5rem;
    }

    &.active {
      color: #898894!important;
    }

    &:hover {
      color: #898894!important;
      background-color: #fff;
    }

    &:after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      display: inline-block;
      border-radius: 50%;
      right: -20px;
      top: 45%;
      background-color: #898894;
      @media screen and (max-width: 768px) {
        right: -15px;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
