@import "./../../assets/styles/mixins/variables";

.table-block {

  .table-header {
    padding: 20px 0 10px 0;
    display: flex;
    justify-content: flex-end;

    .search-input {
      display: flex;
      flex-direction: column;
      min-width: 300px;
      @media screen and (max-width: 768px) {
        min-width: 50vw;
      }
      span {
        font-size: 0.625rem;
        margin-bottom: 4px;
      }

      input {
        border-radius: 8px;
        border: 1px solid #D5D5D5;
        padding: 4px 10px;
        font-size: 0.875rem;
        outline: none !important;

        &::placeholder {
          font-size: 0.75rem;
          color: lightgray;
        }
      }
    }
  }
}

.table-wrapper {
  overflow: auto;
  border-style: solid;
  border-color: #C5C5C5;
  border-radius: 8px;
  border-width: 0 1px;

  &.full-border {
    border-width: 1px;
    overflow-x: hidden;
    @media screen and (max-width: 768px) {
      overflow-x: auto;
    }
  }

  table {
    margin-bottom: 0;

    thead {
      tr {
        border-bottom: 1px solid #C5C5C5;

        th {
          font-size: 0.625rem;
          border-right: 1px solid #C5C5C5;

          &:last-of-type {
            border-right: none;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #C5C5C5;

        &.selected {
          background: transparent linear-gradient(76deg, #ee8136 0%, #f2b043 100%) 0 0 no-repeat padding-box;

          td {
            color: white;
          }
        }

        &.selectable {
          cursor: pointer;
        }

        th, td {

          font-size: 0.625rem;
          border-right: 1px solid #C5C5C5;

          &:last-of-type {
            border-right: none;
          }
        }

        .table-no-information {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.messages-link {
  &:link, &:visited {
    background-color: #ffa54e;
    border-radius: 5px;
    color: white;
    margin-left: 3%;
    padding: 0.1rem 0.3rem; 
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }

  &:hover, &:active {
    background-color: #d2751d;
  }

  &-gray {
    &:link, &:visited {
      background-color: $gray1;
      color: white;
    }
    &:hover {
      background-color: white;
      color: $gray1;
    }
  }
}