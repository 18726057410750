.btn {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  line-height: 1rem;
  border-radius: 8px;
  &:focus {
    box-shadow: none;
  }
  &-orange {
    background-color: $orange;
    color: white;
    &:hover:not(:disabled) {
      background-color: white;
      color: $orange;
      border: 1px solid $orange;
    }
    &:disabled {
      cursor: not-allowed;
      color: white;
    }
  }
  &-gray {
    background-color: $gray1;
    color: white;
    &:hover {
      background-color: white;
      color: $gray1;
      border: 1px solid $gray1;
    }
  }
}