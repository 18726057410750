header {
  height: 62px;
  background: #ffffff;
  box-shadow: 0 3px 6px #00000029;
  position: fixed;
  width: calc(100% - 200px);
  z-index: 2;
  padding: 0 20px;

  .user-info {
    .dropdown-toggle {
      background-color: transparent;
      border: none;
      &:after {
        display: none;
      }
    }

    .user-button {
      background-color: transparent!important;
      padding: 0;
      outline: none;
      box-shadow: none;

      &:focus, &:active {
        outline: none!important;
        box-shadow: none!important;
        background-color: transparent!important;
      }
    }

    .user-image {
      position: relative;
      box-sizing: border-box;
      width: 35px;
      min-width: 35px;
      height: 35px;
      min-height: 35px;
      border-radius: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: background-color 0.4s;
    }

    .username {
      font-size: 12px;
      color: #6a6873;
    }

    .phone {
      font-size: 12px;
      font-weight: 200;
      color: #898894;
    }

    .company {
      font-size: 12px;
      font-weight: 200;
      color: #898894;
    }
  }
  @media screen and (max-width: 768px) {
    width: calc(100% - 65px);
  }
}
