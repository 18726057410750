//Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

@import '~bootstrap/scss/bootstrap';
@import './mixins/input_placeholder.scss';
@import './mixins/variables.scss';
@import './mixins/transitions.scss';
@import './mixins/transitions.scss';

//Global
@import './global';

//Pages
@import './pages/login';
@import './pages/table';
@import './dropdowns';
@import './icomoon.css';

// components
@import './components/buttons.scss';

// utils
@import './utils.scss';
