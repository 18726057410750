@import "./../../assets/styles/mixins/variables";

.alert-back {}

.alert-block {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: calc(50% - 200px);
  width: 400px;
  min-height: 50px;
  //max-height: 60px;
  border-radius: 4px;
  box-shadow: 0 0 10px #0000001f;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  transform: translateY(calc(100% + 20px));
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);

  &.isVisible {
    transform: translateY(-10px);
    transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }

  p {
    font-weight: 500;
    font-size: 0.937rem;
    color: white;
    margin-bottom: 0;
  }

  &.error {
    background-color: $red1;
  }

  &.warning {
    background-color: $orange2;
  }

  &.notification {
    background-color: $blue1;
  }

  &.success {
    background-color: $green1;
  }

}
