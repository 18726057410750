@import 'assets/styles/mixins/variables.scss';

.addEnterpriseButton {
  font-size: 0.85rem;
  top: -5px;
  right: 0;
  transform: translateY(-100%);
  composes: orange position-absolute from global;
}

.iconAccept {
  &:hover {
    color: $green1;
  }
  composes: mr-3 cursor-pointer from global;
}

.iconDecline {
  &:hover {
    color: $red1;
  }
  composes: cursor-pointer from global;
}
