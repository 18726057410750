.custom-dropdown {
  &.show .btn {
    background-color: transparent !important;
  }

  .btn {
    background-color: white;
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;;
    font-size: 14px;
    color: #898894;
    position: relative;
    height: 40px;
    border: 1px solid #D5D5D5;
    padding-right: 30px;
    .icons {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      i {
        font-size: 10px;
        color: #898894;
      }
    }

    &:focus, &:active {
      outline: none;
      box-shadow: none;
    }
  }

  .dropdown-toggle {
    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    border: none;
    z-index: 100;
    padding: 0;
    margin: 0;
    border-radius: 8px;
    //&.show {
    //  top: -40px !important;
    //  left: -1px !important;
    //  &:focus, &:active {
    //    outline: none;
    //  }
    //}

    .dropdown-item {
      background-color: #ffffff;
      box-shadow: 0px 3px 6px #00000029;
      margin-bottom: 1px;
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;;
      color: $dashboardAside;
      text-transform: capitalize;
      z-index: 1000;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      padding-left: 16px;
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
      &:first-child{
        border-radius: 8px 8px 8px 8px;
      }

      &.link-item {
        padding: 0;
        height: 24px;
        text-align: center;
        z-index: 100;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background-color: $white;

          &:hover {
            background-color: $lightGrey;
          }
        }
      }

      &:hover {
        background-color: $lightGrey;
      }

      &:focus, &:active {
        color: $dashboardAside;
        outline: none;
      }

      .icon-checked {
        margin-left: 10px;
      }
    }
  }

  &.dropdown-no-style {
    height: 40px;
    width: 20px;
    margin: 0 auto;

    &:focus, &:active {
      outline: none;

      .btn {
        border: none;
        box-shadow: none;
        background-color: transparent;
        outline: none;
      }
    }

    .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
      background-color: transparent;
    }

    .btn {
      background-color: transparent;
      border: none;
      padding: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 0.2s linear;
      opacity: 0.7;

      &:focus, &:active {
        border: none;
        box-shadow: none !important;
        background-color: transparent;
        outline: none;
        opacity: 1;
      }

      i.icon-dots {
        font-size: 18px;
        color: $dashboardAside;
        justify-content: center;
        align-items: center;
      }
    }

    .dropdown-menu {
      .dropdown-item {
        justify-content: center;
        padding: 0;
        height: 24px;
      }

      .link-item {
        &.disabled {
          opacity: 0.7;
        }
      }

      //&.show {
      //  right: -6px;
      //  top: 34px !important;
      //  left: auto !important;
      //  margin: 0;
      //  transform: none !important;
      //  padding: 0;
      //}
    }
  }

  &.slim {
    height: 24px;
    display: flex;
    align-items: flex-start;

    &.show {
      .btn {
        background-color: $orange !important;
      }
    }

    .btn {
      background-color: $orange;
      border: none;
      height: 100%;
      padding: 0 24px 0 8px;
      position: relative;
      font-size: 10px;
      font-weight: 500;

      &:focus, &:active {
        background-color: $orange;
        box-shadow: none !important;
      }

      &:hover {
        background-color: $orange !important;
      }

      .icon-arrow-down {
        position: absolute;
        right: 8px;
        left: auto;
        font-size: 8px;
        top: 9px;
        transition: all 0.2s ease-in-out;
      }
    }

    .dropdown-menu {
      padding: 0;
      width: auto;
      min-width: 100% !important;
      overflow: visible;

      .dropdown-item {
        height: 25px;
        font-size: 10px;
        font-weight: 500;
        padding: 0 8px;
        margin-bottom: 0;
        border-radius: 2px;
        background-color: #FBE5B3;
        border: 1px solid $inputBorder;
        box-shadow: 0 3px 6px #00000029;
        transition: all 0.2s linear;

        & + .dropdown-item {
          border-top: none;
        }

        &:hover {
          background-color: rgba(251, 229, 179, 0.7);
        }
      }
    }

    &.status {
      background-color: white;
      box-shadow: #00000029 0 3px 6px;
      min-width: 81px;
      border-radius: 2px !important;
      z-index: 0;

      &.open {
        z-index: 10;
      }

      .btn {
        border-radius: 2px !important;
        background-color: white !important;
        font-size: 10px;
        color: #7E7E7E;
        font-weight: 500;
        text-transform: capitalize;
        text-align: left;
        padding: 0 24px 0 24px;

        .status-color {
          position: absolute;
          left: 8px;
          top: 50%;
          transform: translateY(-50%);
          width: 11px;
          height: 11px;
          border-radius: 50%;
        }
      }

      .dropdown-menu {
        border: none;
        background-color: transparent;
        top: -2px !important;
        width: auto;

        .dropdown-item {
          border: none;
          margin-top: 1px;
          position: relative;
          padding-left: 24px;
          background-color: white;

          .status-color {
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
            width: 11px;
            height: 11px;
            border-radius: 50%;
          }

          &:hover {
            background-color: $lightGrey;
          }

          &:focus, &:active {
            color: $dashboardAside;
            outline: none;
          }
        }
      }
    }
  }

  &.standard {
    height: 32px;
    display: flex;
    align-items: flex-start;

    &.show {
      .btn {
        background-color: white !important;

        .icon-arrow-down {
          top: 10px;
          transform: rotateX(180deg);
        }
      }
    }

    .btn {
      background-color: white;
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 8px;
      padding-right: 24px;
      position: relative;
      font-size: 14px;
      color: #7E7E7E;
      border: 1px solid #C5C5C5;
      text-align: left;

      &:focus, &:active {
        box-shadow: none !important;
        background-color: white;
        border-color: #C5C5C5;
        color: #7E7E7E;
      }

      .icon-arrow-down {
        position: absolute;
        right: 8px;
        left: auto;
        font-size: 10px;
        top: 12px;
        transition: all 0.2s ease-in-out;
        color: #444445;
      }
    }

    .dropdown-menu {
      border-radius: 0 0 2px 2px;
      padding: 0;
      border: 1px solid $inputBorder;
      overflow: hidden;
      width: 100%;
      min-width: 100% !important;

      .dropdown-item {
        border: none;
        margin-bottom: 0;
        box-shadow: none;
        padding-left: 8px;
        padding-right: 8px;
        height: 30px;
        font-size: 14px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: white;

        a {
          background-color: $white;
        }

        & + .dropdown-item {
          border-top: 1px solid $inputBorder;
        }

        &:hover {
          background-color: $lightGrey;
        }

        &:focus, &:active {
          color: $dashboardAside;
          outline: none;
        }
      }

    }
  }

  &.show {
    .btn {
      background-color: #7E7E7E;

      .icon-arrow-down {
        top: 8px;
        transform: rotateX(180deg);
      }
    }
  }
}